















import QuestionsList from "@/components/QuestionsList";
import SearchQuestion from "@/components/SearchQuestion.vue";
import {Component, Vue} from "vue-property-decorator";


@Component({
  name: 'Questions',
  components: {SearchQuestion, QuestionsList}
})
export default class Questions extends Vue {

}

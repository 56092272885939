































import {Component, Vue} from "vue-property-decorator";

@Component
export default class SearchQuestion extends Vue {
  public query = '';

  public onChange(): void {
    console.log(this.query);
    this.$root.$emit('searchQuestion', {
      query: this.query
    });
  }
}

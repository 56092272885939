



























import {Component, Vue} from "vue-property-decorator";
import Settings from "../packages/settings/Settings";

@Component
export default class LatestQuestions extends Vue {
  public list = [];
  public settings: Settings;
  public actualPage: number;
  public totalItems: number;
  public query: string;

  constructor() {
    super();
    this.settings = new Settings();
    this.actualPage = 1;
    this.totalItems = 0;

  }

  public loadData(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    this.axios.get(this.settings.settings.host + '/api/episodes',{
      params: {
        page: this.actualPage.toString(),
        'order[date_add]': 'desc',
        status: 1,
        'episodeQuestions.question': this.query
      }
    }).then(response => {
      this.totalItems = response.data['hydra:totalItems'];
      this.list = response.data['hydra:member'];
    });
  }

  getVideoCodeFromLink(link: string): string {
    return link
        .replace('https://www.youtube.com/watch?v=', '')
        .replace('&ab_channel=FundacjaProSpe', '')
  }

  created(): void {
    console.log('question list loaded');
    this.loadData();
  }

  mounted(): void {
    this.$root.$on('searchQuestion', data => {
      console.log(data);
      this.query = data.query;
      if (data.query.length === 0) {
        this.query = undefined;
      }
      this.loadData();
    });
  }
}
